// libraries
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import Image from './../../utils/components/Image';

import homeImageFile from '../../public/homeImage.png';
import BSELogo from '../../public/BSELogo.png';
import logoKJ from '../../public/logoKJCropped.png';
import BusinessAnalyst from '../../public/45714-business-analyst.json';
function Home() {
  return (
    <>
      <img src={BSELogo} width="40%"></img>
      {/* <div className="socials">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/c/KJSIEITofficial"
          className="social-icon icon-only yt"
        >
          <i className="fab fa-youtube" />
        </a>
        <a
          href="https://www.facebook.com/kjsieitofficial"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon icon-only li"
        >
          <i className="fab fa-facebook-square" />
        </a>
        <a
          href="https://www.instagram.com/kjsieit_22"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon icon-only gi"
        >
          <i className="fab fa-instagram" />
        </a>
      </div> */}

      <div className="home">
        <Helmet>
          <title>Home - Bombay Stock Exchange </title>
          <meta name="description" content="BSE India" />
        </Helmet>

        <div className="details">
          <div className="btns"></div>
          <div className="btns">
            <Link
              to={{ pathname: 'https://www.bseindia.com/' }}
              target="_blank"
              className="btn-outline-primary hg"
            >
              BSE India Website
            </Link>
          </div>
          <div className="btns">
            <Link to="/downloads" className="btn-primary hg">
              Download Recent <br></br> Form 16A
            </Link>
          </div>
        </div>
        {/* <Lottie animationData={BusinessAnalyst} /> */}

        <div className="image">
          <Lottie animationData={BusinessAnalyst} />
          {/* <Image src={homeImageFile} /> */}
        </div>
      </div>
    </>
  );
}

export default Home;
